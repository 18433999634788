import * as React from "react";
import Img from "gatsby-image";
import "./CaptionedImage.scss";

const CaptionedImage = ({ fluid, aspectRatio, caption, sub }) => {
  const image = aspectRatio ? { ...fluid, aspectRatio } : fluid;

  return (
    <figure>
      <Img className="rounded" fluid={image} />
      {caption && (
        <figcaption>
          {caption}
          {sub && <span className="sub">{sub}</span>}
        </figcaption>
      )}
    </figure>
  );
};

export default CaptionedImage;
