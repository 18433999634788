import MasonrySection from "./MasonrySection";
import SideBySideImageSection from "./SideBySideImageSection";
import SimpleImageSection from "./SimpleImageSection";
import StaggeredSideBySideImageSection from "./StaggeredSideBySideImageSection";
import ScrollableImageSection from "./ScrollableImageSection";

export default {
  MasonrySection,
  SideBySideImageSection,
  SimpleImageSection,
  StaggeredSideBySideImageSection,
  ScrollableImageSection,
};
