import * as React from "react";
import classNames from "classNames";
import "./SectionBreak.scss";

const SectionBreak = ({ title, type }) => (
  <div className={classNames({ "section-break": true, [type]: true })}>
    <h2>{title}</h2>
  </div>
);

export default SectionBreak;
