import * as React from "react";
import Img from "gatsby-image";
import ScrollableImages from "../ScrollableImages";
import "./Section.scss";

export const SectionImage = ({ images, sectionImages }) => (
  <div className="row">
    <div className="col-12 section-hero">
      {images.length === 1 && (
        <Img className="rounded" fluid={sectionImages[images[0].image]} />
      )}
      {images.length > 1 && (
        <ScrollableImages
          images={images}
          sectionImages={sectionImages}
          orientation="landscape"
        />
      )}
    </div>
  </div>
);

export const SectionTitle = ({ color, title, description }) => (
  <div className="row">
    <div className="col-12 section-title">
      <h3 className={`color-${color}`}>{title}</h3>
      {description && description.map((desc) => <p>{desc}</p>)}
    </div>
  </div>
);
