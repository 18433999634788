import * as React from "react";
import classNames from "classnames";
import { SectionTitle, SectionImage } from "../../components/Section";
import BackgroundImage from "gatsby-background-image";
import CaptionedImage from "../../components/CaptionedImage";
import "./MasonrySection.scss";

const MasonrySection = ({ header, featuredImages, images, sectionImages }) => (
  <div className="container">
    {featuredImages && (
      <SectionImage images={featuredImages} sectionImages={sectionImages} />
    )}
    <div className="row">
      <div className="col-12 col-md-9">
        <SectionTitle {...header} />
      </div>
    </div>
    <div className="row hide-small">
      <div className="col-12">
        <div className="grid-layout">
          {images.map((image) => (
            <BackgroundImage
              className={classNames({
                "grid-item": true,
                [`span-col-${image.col}`]: image.col,
                [`span-row-${image.row}`]: image.row,
              })}
              fluid={sectionImages[image.image]}
              style={{
                borderRadius: "1rem",
                overflow: "hidden",
              }}
            />
          ))}
        </div>
      </div>
    </div>
    <div className="row show-small">
      {images.map((image) => (
        <div className="col-12 col-md-6">
          <CaptionedImage
            fluid={sectionImages[image.image]}
            aspectRatio={image.aspectRatio}
            caption={image.caption}
            sub={image.sub}
          />
        </div>
      ))}
    </div>
  </div>
);

export default MasonrySection;
