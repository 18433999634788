import * as React from "react";
import "./Navigation.scss";

const Navigation = () => (
  <nav>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ul>
            <li>
              <a href="#our-love-story">Our Love Story</a>
            </li>
            <li>
              <a href="#engagement">Engagement</a>
            </li>
            <li>
              <a href="#big-day">Wedding</a>
            </li>
            <li>
              <a
                href="https://www.blueprintregistry.com/registry/lanie-and-matt-wedding-registry"
                target="_blank"
                rel="noreferrer"
              >
                Registry
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
);

export default Navigation;
