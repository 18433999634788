import * as React from "react";
import "./Footer.scss";

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <ul>
            <li>
              <a href="#our-love-story">Our Love Story</a>
            </li>
            <li>
              <a href="#engagement">Engagement</a>
            </li>
            <li>
              <a href="#big-day">Wedding</a>
            </li>
            <li>
              <a
                href="https://www.blueprintregistry.com/registry/lanie-and-matt-wedding-registry"
                target="_blank"
                rel="noreferrer"
              >
                Registry
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          © 2020 Lanie and Matt. Engagement & Wedding photos by{" "}
          <a
            href="https://www.simoneanne.com/"
            target="_blank"
            rel="noreferrer"
          >
            Simone Anne
          </a>
          .
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
