import * as React from "react";
import classNames from "classNames";
import CaptionedImage from "../../components/CaptionedImage";
import { SectionTitle, SectionImage } from "../../components/Section";

const SimpleImageSection = ({
  header,
  featuredImages,
  rowSize,
  images,
  sectionImages,
}) => {
  const size = 12 / parseInt(rowSize);

  return (
    <div className="container">
      {featuredImages && (
        <SectionImage images={featuredImages} sectionImages={sectionImages} />
      )}
      <div className="row">
        <div className="col-12 col-md-9">
          <SectionTitle {...header} />
        </div>
      </div>
      <div className="row">
        {images.map((image) => (
          <div
            className={classNames({
              "col-12": true,
              "col-sm-6": size !== 12,
              [`col-lg-${size * (image.span ? image.span : 1)}`]: true,
            })}
          >
            <CaptionedImage
              fluid={sectionImages[image.image]}
              aspectRatio={image.aspectRatio}
              caption={image.caption}
              sub={image.sub}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimpleImageSection;
