import * as React from "react";
import classNames from "classnames";
import CaptionedImage from "../../components/CaptionedImage";
import "./ScrollableImages.scss";

const ScrollableImages = ({ images, sectionImages, orientation }) => {
  return (
    <div className="row overflow">
      {images.map((image) => (
        <div
          className={classNames({
            "overflow-child": true,
            "col-10 col-sm-5": orientation === "portrait",
            "col-11": orientation === "landscape",
          })}
        >
          <CaptionedImage
            fluid={sectionImages[image.image]}
            aspectRatio={image.aspectRatio}
            caption={image.caption}
            sub={image.sub}
          />
        </div>
      ))}
    </div>
  );
};

export default ScrollableImages;
