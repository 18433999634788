import * as React from "react";
import classNames from "classNames";
import CaptionedImage from "../../components/CaptionedImage";
import SimpleCarousel from "../../components/SimpleCarousel";
import { SectionTitle, SectionImage } from "../../components/Section";

const SideBySideImageSection = ({
  header,
  featuredImages,
  images,
  sectionImages,
  flipped,
}) => (
  <div className="container">
    {featuredImages && (
      <SectionImage images={featuredImages} sectionImages={sectionImages} />
    )}
    <div className="row">
      <div
        className={classNames({
          "col-12": true,
          "col-sm-5": true,
          "d-flex": true,
          "align-items-center": true,
          "order-last": flipped,
          "offset-sm-1": flipped,
        })}
      >
        <SectionTitle {...header} />
      </div>
      <div
        className={classNames({
          "col-12": true,
          "col-sm-6": true,
          "offset-sm-1": !flipped,
          "order-first": flipped,
        })}
      >
        {images.length === 1 && (
          <CaptionedImage
            fluid={sectionImages[images[0].image]}
            aspectRatio={images[0].aspectRatio}
            caption={images[0].caption}
            sub={images[0].sub}
          />
        )}
        {images.length > 1 && (
          <SimpleCarousel sectionImages={sectionImages} images={images} />
        )}
      </div>
    </div>
  </div>
);

export default SideBySideImageSection;
