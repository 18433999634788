import React from "react";
import { Helmet } from "react-helmet";

const title = "Lanie & Matt Heck";
const description =
  "Delanie Ricketts and Matthew Heck were married October 28, 2020 at Folktale Winery in Carmel, CA.";

const SEO = () => (
  <Helmet
    htmlAttributes={{
      lang: "en-us",
    }}
    title={title}
    meta={[
      {
        name: "description",
        content: description,
      },
      {
        property: "og:title",
        content: title,
      },
      {
        property: "og:description",
        content: description,
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        name: "twitter:card",
        content: "summary",
      },
      {
        name: "twitter:creator",
        content: "Delanie & Matthew Heck",
      },
      {
        name: "twitter:title",
        content: title,
      },
      {
        name: "twitter:description",
        content: description,
      },
    ]}
  />
);

export default SEO;
