import * as React from "react";
import ScrollableImages from "../../components/ScrollableImages";
import { SectionTitle, SectionImage } from "../../components/Section";

const ScrollableImageSection = ({
  header,
  featuredImages,
  images,
  sectionImages,
  orientation,
}) => (
  <div className="container">
    {featuredImages && (
      <SectionImage images={featuredImages} sectionImages={sectionImages} />
    )}
    <div className="row">
      <div className="col-12 col-md-9">
        <SectionTitle {...header} />
      </div>
    </div>
    <ScrollableImages
      images={images}
      sectionImages={sectionImages}
      orientation={orientation}
    />
  </div>
);

export default ScrollableImageSection;
