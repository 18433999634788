import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "./App.scss";
import Navigation from "../components/Navigation";
import SectionBreak from "../components/SectionBreak";
import SEO from "../components/SEO";
import Footer from "../components/Footer";
import Img from "gatsby-image";
import content from "../content/content.json";
import Templates from "../templates";

const convert = (edges) => {
  const obj = {};

  for (const edge of edges) {
    const path = edge.node.relativePath.split("/");

    let objPlace = obj;

    for (const [index, value] of path.entries()) {
      if (index + 1 === path.length) {
        objPlace[edge.node.name] = edge.node.childImageSharp.fluid;
      } else {
        if (!objPlace[value]) {
          objPlace[value] = {};
        }
        objPlace = objPlace[value];
      }
    }
  }

  return obj;
};

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      header: file(
        relativePath: { eq: "2020_10_28_DelanieMattWedding-560.jpg" }
      ) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(quality: 75, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      engagement: file(
        relativePath: { eq: "2020_10_27_DelanieMattEngagement-255.jpg" }
      ) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(quality: 75, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wedding: file(
        relativePath: { eq: "2020_10_28_DelanieMattWedding-935.jpg" }
      ) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fluid(quality: 75, maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allImages: allFile {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid {
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  `);

  const allImages = convert(data.allImages.edges);

  return (
    <div>
      <SEO />
      <header>
        <Img fluid={data.header.childImageSharp.fluid} />
        <Navigation />
      </header>
      <main>
        {content.map((article) => (
          <article id={article.slug}>
            {article.image && (
              <Img fluid={data[article.image].childImageSharp.fluid} />
            )}
            <SectionBreak type={article.template} title={article.title} />
            {article.sections.map((section) => {
              const CustomTag = Templates[section.template];
              return (
                <div className="section">
                  <CustomTag
                    header={section.header}
                    featuredImages={section.images}
                    flipped={section.flipped}
                    rowSize={section.content.rowSize}
                    images={section.content.images}
                    sectionImages={allImages[article.slug][section.header.slug]}
                    orientation={section.content.orientation}
                  />
                </div>
              );
            })}
          </article>
        ))}
      </main>
      <Footer />
    </div>
  );
};

export default IndexPage;
