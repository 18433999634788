import * as React from "react";
import CaptionedImage from "../../components/CaptionedImage";
import { SectionTitle, SectionImage } from "../../components/Section";

const StaggeredSideBySideImageSection = ({
  header,
  featuredImages,
  images,
  sectionImages,
}) => {
  const half = Math.floor(images.length / 2);
  const first = images.slice(0, half);
  const second = images.slice(half);

  return (
    <div className="container">
      {featuredImages && (
        <SectionImage images={featuredImages} sectionImages={sectionImages} />
      )}
      <div className="row">
        <div className="col-12 col-lg-6">
          <SectionTitle {...header} />
          {first.map((image) => (
            <CaptionedImage
              fluid={sectionImages[image.image]}
              aspectRatio={image.aspectRatio}
              caption={image.caption}
              sub={image.sub}
            />
          ))}
        </div>
        <div className="col-12 col-lg-6">
          {second.map((image) => (
            <CaptionedImage
              fluid={sectionImages[image.image]}
              aspectRatio={image.aspectRatio}
              caption={image.caption}
              sub={image.sub}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StaggeredSideBySideImageSection;
