import * as React from "react";
import "./SimpleCarousel.scss";
import CaptionedImage from "../CaptionedImage";
import Slider from "react-slick";

const SimpleCarousel = ({ images, sectionImages }) => (
  <Slider
    dots={true}
    accessibility={true}
    arrows={true}
    infinite={true}
    speed={500}
    slidesToShow={1}
    slidesToScroll={1}
  >
    {images.map((image) => (
      <div className="carousel-slide" key={image.image}>
        <CaptionedImage
          fluid={sectionImages[image.image]}
          aspectRatio={image.aspectRatio}
          caption={image.caption}
          sub={image.sub}
        />
      </div>
    ))}
  </Slider>
);

export default SimpleCarousel;
